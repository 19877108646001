import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Gallery from "../../Gallery/Gallery";

const PageGallery = ({ block }) => {
  return <Gallery items={block.pages} hasIcon={block.includeArrow} />;
};

export default PageGallery;

PageGallery.propTypes = {
  block: PropTypes.shape({
    includeArrow: PropTypes.bool,
    pages: PropTypes.array.isRequired,
  }),
};

export const query = graphql`
  fragment PageGallery on DatoCmsPagegallery {
    id: originalId
    model {
      apiKey
    }
    includeArrow
    pages {
      title
      slug
      galleryItem: content {
        ... on DatoCmsStandardhero {
          image: heroImage {
            alt
            gatsbyImageData(width: 768)
          }
        }
      }
    }
  }
`;
